.head-text{
    font-size: 22px;
    text-transform: uppercase;
    font-weight: bold;
    color: #043B5C;
    letter-spacing: 1px;
}

.buttons-right{
    float: right;
}

.btn-export{
    padding-left: 25px;
    padding-right: 25px;
    background-color: white;
    border-color: blue;
    color: blue;
}

.borderleft{
    background:#EEF4FB;
}

.font-text{
    font-size: 16px;
}

.btn-text{
    font-size: 11px;
}

.btn-success{
    border-radius: 20px;
    padding-left: 22px;
    padding-right: 22px;
}

.btn-danger{
    border-radius: 20px;
}

.col-md-2, .col-md-4, .col-md-6{
    padding-left: 0 !important;
}



@media only screen and (max-width: 600px) {
    .font-text{
        white-space: nowrap;
    }
    .buttons-right{
        // margin-top: 20px;
        // margin-right: 50px;
        margin: 15px auto;
        float: none;
    }
    .btn-export{
        margin-right: 10px;
    }
    .btn-success, .btn-danger{
        white-space: nowrap;
        font-size: 10px;
    }
    .cardtop{
        margin-top: 30px;
    }
    .r{
        float: right !important;
    }
}

@media only screen and (min-width: 768px) {
    .btn-success, .btn-danger{
        white-space: nowrap;
        font-size: 10px;
    }
}