.position-center,
.position-center-column {
    display: flex;
    justify-content: center;
    align-items: center;
}

.position-center-column {
    flex-direction: column;
}
