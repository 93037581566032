.content {
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.text-settings-color {
  color: #043b5c;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.hrrr {
  border-color: #285873;
}

.px {
  padding-left: 0px;
  padding-top: 15px;
}

.text-cap {
  text-transform: uppercase;
  font-size: 14px !important;
  font-weight: 600;
  color: #043b5c7f;
}

.wallet-bank-text {
  color: #043b5c;
  font-size: 13px;
  font-weight: 500;
}

.right {
  font-size: 13px;
  color: blue;
  font-weight: 520;
  float: right !important;
  text-align: right !important;
}

.react-switch {
  float: right;
}

.p-textsize {
  font-size: 13px;
  color: #1461a2;
}

.btn-save {
  color: #ffffff;
  background-color: #4786ff;
}

.spacing {
  margin-top: 20px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .save-btn {
    margin: auto;
    text-align: center;
  }
}
