.container{
    background: #ffffff !important;
}

.card-design{
    border-radius: 8px;
    border: 3px solid #F3F3F4;
    box-shadow: rgba(0, 59, 0, 0.15) 0px 0.11rem 0.75rem -4px;
}

.hrrr{
    color: #043B5C;
}

.size{
    max-width: 120%;
    width: 104%;
    background: url('../../../assets/images/wallet/background.png') 0% 0% no-repeat;
    opacity: 1;
    height: 153px;
    margin-left: -16px;
}

.svg{
    max-width: 100%;
    width: 88%;
    z-index: 10;
    margin-left: 20px;
}

.mt-5{
    margin-left: -20px;
}

.btn-secondary{
    border-radius: 20px;
    background-color: #F3F3F4;
    color: #043A5C;
}


.izyvest-text{
    color: #F3F3F4;
    margin-bottom: 5px;
    font-size: 16px;
    white-space: nowrap;
}

.izyvest-lastfunded{
    color: #F3F3F4;
    margin-bottom: 5px;
    font-size: 16px;
    white-space: nowrap;
}

.izyvest-money{
    color: #F3F3F4;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
}

.text-position{
    text-align: center;
}


.head-color{
    color: #225370;
    font-weight: 700 !important;
    font-size: 19px;
    text-transform: uppercase;
}

.small-i{
    color: #225370;
    font-weight: 550;
    text-transform: lowercase !important;
}

.text-color{
    color: #225370;
    font-weight: 550;
}

.img-size{
    width: 26px;
    height: 26px;
    padding-right: 5px;
}

@media (min-width: 769px) and (max-width: 991px) {
    .card-design{
        border-radius: 8px;
        border: 3px solid #F3F3F4;
        box-shadow: rgba(0, 59, 0, 0.15) 0px 0.11rem 0.75rem -4px;
        max-width: 100%;
        width: 100%;
    }
    .size{
        max-width: 100%;
        width: 100%;
        background: #FFFFFF !important;;
    }

    .svg{
        display: none;
    }

    .mt-5{
        margin-top: 0px !important;
    }

    .colmb{
        display: none;
    }

    .mb-4{
        margin-bottom: 0px !important;
    }

    .mb{
        display: block !important;
        margin: 2px auto !important;
    }

    .fl{
        display: block !important;
        margin: 0 auto !important;
    }

    .btn-secondary{
        border-radius: 20px;
        background-color: #F3F3F4;
        color: #043A5C;
        font-size: 10px !important;
        white-space: nowrap;
        margin-left: -30px;
    }
    
    .izyvest-text{
        color: #043A5C;
        margin-bottom: 5px;
        font-size: 16px;
    }

    .izyvest-lastfunded{
        color: #043A5C;
        margin-bottom: 5px;
        font-size: 16px;
        margin-left: -30px;
    }
    
    .izyvest-money{
        color: #043A5C;
        font-size: 15px;
        font-weight: bold;
    }

    .secondrow{
        margin-top: -60px !important;
    }
}

@media (max-width: 768px) {

    
    .card-design{
        border-radius: 8px;
        border: 3px solid #F3F3F4;
        box-shadow: rgba(0, 59, 0, 0.15) 0px 0.11rem 0.75rem -4px;
        max-width: 100%;
        width: 100%;
    }

    .size{
        max-width: 100%;
        width: 100%;
        background: #FFFFFF !important;;
    }

    .svg{
        display: none;
    }

    .mt-5{
        margin-top: 0px !important;
    }

    .colmb{
        display: none;
    }

    .mb-4{
        margin-bottom: 0px !important;
    }

    .mb{
        display: block !important;
        margin: 2px auto !important;
    }

    .fl{
        display: block !important;
        margin: 0 auto !important;
    }

    .btn-secondary{
        border-radius: 20px;
        background-color: #F3F3F4;
        color: #043A5C;
        font-size: 10px !important;
        white-space: nowrap;
        margin-left: -30px;
    }
    
    .izyvest-text{
        color: #043A5C;
        margin-bottom: 5px;
        font-size: 16px;
    }

    .izyvest-lastfunded{
        color: #043A5C;
        margin-bottom: 5px;
        font-size: 16px;
        margin-left: -30px;
    }
    
    .izyvest-money{
        color: #043A5C;
        font-size: 15px;
        font-weight: bold;
    }

    .secondrow{
        margin-top: -60px !important;
    }
    
}