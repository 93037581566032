.btn-grey{
    background-color: #fafafa;
    color: #062239;
    border-radius: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 35px;
    padding-right: 35px;

}

.btn-green{
    background-color: #4EBD65;
    color: white;
}

.list-status-button{
    font-size: 14px;
    width: 100px;
    border-radius: 20px;
    z-index: 1;
    position: absolute;
    top: 20px;
    left: 3px;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #043B5C;
    border-radius: 3px;
    z-index: 100;
  }

  .card:hover .overlay {
    opacity: 0.8;
  }

  .text {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

/* responsiveness */
/* for mobile */
@media only screen and (max-width: 600px) {
    .card{
        margin-bottom: 15px;
    }
}

@media only screen and (min-width: 600px) {
    .card{
        margin-bottom: 15px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .card{
        margin-right: 25px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1024px) {
    .card{
        margin-right: 15px;
    }
}
