/* It targets only the app used within the browser */
@media (display-mode: browser) {
}
/* It targets only the app used with a system icon in standalone mode */
@media (display-mode: standalone) {
}
/* It targets only the app used with a system icon in all mode */
@media (display-mode: standalone),
  (display-mode: fullscreen),
  (display-mode: minimal-ui) {
}

@mixin mobile {
  @media only screen and (max-width: 599px) {
    @content;
  }
}

@mixin mobile-up {
  @media only screen and (min-width: 600px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin tablet-up {
  @media only screen and (min-width: 767px) {
    @content;
  }
}
@mixin tablet-portait {
  @media only screen and (max-width: 899px) {
    @content;
  }
}

@mixin tablet-portait-up {
  @media only screen and (min-width: 900px) {
    @content;
  }
}

@mixin desktop-up {
  @media only screen and (min-width: 1125px) {
    @content;
  }
}
@mixin desktop-down {
  @media only screen and (max-width: 1124px) {
    @content;
  }
}
