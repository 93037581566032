.modal-title{
    color: #043B5C;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: -7px;
    text-transform: uppercase;
}

::placeholder{
    font-size: 13px;
}

.idno{
    white-space: nowrap;
}

.modal-subheading{
    color: #043B5C;
    text-transform: uppercase;
    font-size: 15px;
    font-family: sans-serif;
    font-weight: 600;
}

.modal-subheading-emergency{
    color: #043B5C;
    text-transform: uppercase;
    font-size: 15px;
    font-family: sans-serif;
    font-weight: 600;
    margin-left: 5px;
}

.modal-formlabel{
    font-size: 12px;
    color: #043B5C;
    text-transform: uppercase;
    font-weight: 600;
}


.form-control:focus{
    box-shadow: none;
    border-style: 1px solid #062239;
    border-radius: 6px;
}

.form-control{
    border-radius: 10px !important;
}

.form-check-label{
    font-size: 12px;
    color: #043B5C;
}

#form-notice{
    color: blue;
}

#notice-text{
    color: #043B5C;
}

.modal-backbtn{
    color: blue;
    border-color: blue;
}

.btn-next{
    max-width: 100%;
    width: 15%;
}

.btn-submit{
    max-width: 100%;
    width: 15%;
}

.modalnote{
    color: blue;
}

.padding{
    padding-bottom: 1% !important;
}

.btn-light{
    max-width: 100%;
    width: 15%;
    background-color: transparent;
    color: blue;
    border-color: blue;
}

.modal-footer{
    border-top: hidden;
    justify-content: flex-start;
}