.btn {
  color: #232f49;
  display: inline-block;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 11px 21px;
  font-size: 14px;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  font-family: inherit;
  &:active,
  &:focus {
    outline: none;
  }
  &.focus {
    @media (min-width: 1125px) {
      &:focus {
        // outline: -webkit-focus-ring-color auto 1px;
      }
    }
  }
  &:hover {
    transform: scale(0.98);
  }
}

.btn--primary {
  color: #fff;
  background-color: #10609f;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

  &:hover {
    box-shadow: 0 4px 5px -2px rgba(50, 50, 93, 0.11), 0 1px 2px -2px rgba(0, 0, 0, 0.08);
    color: #fff;
  }
}

.btn--danger {
  color: #fff;
  background-color: #f04438;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

  &:hover {
    box-shadow: 0 4px 5px -2px rgba(50, 50, 93, 0.11), 0 1px 2px -2px rgba(0, 0, 0, 0.08);
  }
}

.btn--plain {
  color: #232f49;
  background-color: #fff;
  box-shadow: 0 2px 3px rgba(50, 50, 93, 0.05), 0 1px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  &.btn--crv {
    img {
      height: 13px;
      width: 13px;
      position: relative;
      top: 0px;
      opacity: 0.6;
      margin-right: 6px;
    }
  }

  &:hover {
    box-shadow: 0 4px 5px -2px rgba(50, 50, 93, 0.11), 0 1px 2px -2px rgba(0, 0, 0, 0.08);
  }
}

.btn-outline--blue {
  border: 1px solid #42a99b;
  color: #42a99b;
  background: transparent;

  &.is-loading {
    background: #42a99b;
  }

  &:hover {
    color: white;
    background: #42a99b;
  }
}

.btn--secondary {
  color: #fff;
  background-color: #ff6600;

  &:hover {
    background-color: #b84900;
  }
}

.btn--no-style {
  background: none;
  border: 0;
}

.btn--default {
  background-color: #fff;
  border: 2px solid #10609f;
  color: #10609f;

  &:hover {
    background-color: #dbdbdb;
    color: #10609f;
  }
}

.btn--block {
  display: block;
  width: 100%;
}

.btn--lg {
  font-size: 18px;
}

.btn--sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.btn--crv {
  min-width: 80px;
  // border-radius: 32px;
  border-radius: 8px;

  &.btn--sm {
    padding: 0.45rem 0.5rem !important;
  }

  &.btn--lg {
    padding: 0.45rem 0.9rem !important;
    font-size: 0.875rem;
  }

  &.btn--xs {
    font-size: 12px;
    padding: 0.25rem 0.3rem !important;
    min-width: 50px;
  }

  img {
    height: 15px;
    width: 15px;
    margin-right: 5px;
    position: relative;
    bottom: -2px;
  }
}

.btn--flex {
  display: flex;
  align-items: center;
}

.btn--text {
  padding: 0;
  background-color: transparent;
  font-size: inherit;
  color: inherit;
  outline: none;
  border: none;
}

.btn--continue {
  box-shadow: 0 10px 20px 0 #b3b3b3;
}

.btn.outline {
  background: none;

  &.grey {
    border: 1px solid #d4d5d8;
  }

  &.blue {
    border: 1px solid #42a99b;
  }
}

.btn--success {
  background: #5bb75b;
  color: white;
  border-radius: 75px;
}

.btn.btn--cancel {
  color: #42a99b;
  background-color: #e5e5e5;
}

.btn--call {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2) !important;
  background: #43ab04;
}

.btn {
  &.is-loading {
    color: transparent !important;
    pointer-events: none;
    img,
    svg {
      opacity: 0 !important;
    }

    &::after {
      position: absolute;
      left: calc(50% - (1.5em / 2));
      top: calc(50% - (1.5em / 2));
      position: absolute !important;
      animation: spinAround 0.5s infinite linear;
      border: 2px solid #ffffff;
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: block;
      height: 1.5em;
      width: 1.5em;
    }
  }

  &.btn--default.is-loading::after {
    border: 2px solid #d8d8d8;
  }

  &--link {
    background-color: transparent;
    border: none;
    cursor: pointer;
    // text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
    color: inherit;
    box-shadow: none;
    border-radius: 0px;
    text-align: left;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.floating-btn {
  cursor: pointer;
  right: 20px;
  bottom: 30px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.65), 0 8px 16px -8px rgba(0, 0, 0, 0.3),
    0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  border-radius: 50%;
  height: 60px;
  width: 60px;
  text-align: center;
  font-size: 11px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #42a99b;
  transition: 200ms all;
  z-index: 5;
  outline: 0px;
  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }
  @media (max-width: 767px) {
    height: 53px;
    width: 53px;
    font-size: 10px;
    bottom: 15px;
    z-index: 18;
  }
}

.btn[disabled] {
  opacity: 0.8;
  cursor: not-allowed;
  // pointer-events: none;

  &:hover {
    transform: unset;
  }
}

@-moz-keyframes spinAround {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
