.property-listing-page {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    min-height: 100vh;
    .plp-filter-block {
    }
    .plp-lipman-ad {
        width: 100%;
        height: auto;
        padding: 20px;
        font-family: Nunito;
        border-radius: 5px;
        background-color: #ffffff;
        margin-bottom: 30px;
        p {
            font: normal normal 600 14px/21px Nunito;
            margin-bottom: 15px;
        }
        .plp-la-link {
            color: #0051ee;
        }
        .plp-la-btn {
            height: 30px;
            border-radius: 15px;
            font-size: 12px;
            background-color: #45b29d;
            text-transform: unset;
        }
    }
}
.plans-info-modal {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #043b5c99;
    //backdrop-filter: blur(9px);
    z-index: 4;
    .pim-content-div {
        width: 90%;
        max-width: 400px;
        margin: 100px auto;
        background-color: #ffffff;
        border-radius: 5px;
        padding-bottom: 1px;
    }
    .pim-content-header-div {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid gainsboro;
        display: flex;
        align-items: center;
        font: normal normal 600 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #043b5c;
        padding-left: 30px;
    }
    .pim-content-bottom-div {
        padding: 20px 15px;
        .pim-cbd-p {
            font: normal normal 12px/16px Nunito;
            color: #043b5c;
            letter-spacing: 0.2px;
            margin-bottom: 5px;
        }
        .pim-cbd-div {
            display: flex;
            justify-content: space-between;
            .pim-cbd-div-btn {
                width: 100%;
                text-transform: unset;
            }
            .pim-cbd-div-btn:first-child {
                margin-right: 20px;
            }
        }
    }
}
.lipman-form {
    position: fixed;
    min-height: 300px;
    p {
        color: black;
        font-family: Nunito;
    }
    .pim-loader-div {
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .pim-cbd-div {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        .pim-cbd-div-btn {
            background-color: green;
        }
    }
}
@media screen and (min-width: 700px) {
    #property-listing-page {
        .plp-filter-block {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
        .plp-lipman-ad {
            width: 400px;
        }
    }
    .plans-info-modal {
        .pim-content-bottom-div {
            padding: 10px 20px;
            div {
                margin-bottom: 10px;
            }
            .pim-cbd-p {
                font: normal normal 14px/20px Nunito;
                letter-spacing: 0.5px;
                margin-bottom: 5px;
            }
        }
    }
    .lipman-form {
        position: fixed;
    }
}
