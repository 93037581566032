#single-property-ribbon {
  .spr-summary-flex-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: 800px) {
      flex-direction: column;
      align-items: baseline;
    }

    div {
      max-width: calc(100vw - 150px);
      overflow: hidden;
      .spr-summary-title {
        color: #062239;
        display: block;
        font-family: Open Sans-bold, sans-serif;
        font-weight: 600;
      }
      span {
        overflow: hidden;
        max-width: 170px;
        height: 23px;
        white-space: nowrap;
        width: 100%;
      }
    }
  }
  .spr-accordion-details {
    border-top: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
    width: 100%;
    padding: 15px 0 5px;
  }
}

@media screen and (min-width: 700px) {
  #single-property-ribbon {
    .spr-summary-flex-div {
      .spr-sfd-end-par {
        margin-right: 80px;
      }
    }
  }
}
