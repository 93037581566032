.listing-flex-container {
    min-height: 50vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#apartment-listing-card {
    width: 250px;
    .card-content-alc {
        margin: 20px;
        overflow: hidden;
        h2 {
            margin-top: 0;
            margin-bottom: 5px;
            font: normal normal bold 16px/24px Open Sans;
            letter-spacing: 0px;
            color: #043b5c;
            white-space: nowrap;
            text-transform: capitalize;
            overflow: auto;
        }
        h2::-webkit-scrollbar {
            height: 5px;
        }
        .first-address-block-alc {
            img {
                width: 15px;
            }
            display: flex;
            font: normal normal 400 14px/19px Open Sans;
            color: #043b5c;
            white-space: nowrap;
            overflow: auto;
            margin-bottom: 5px;
        }
        .first-address-block-alc::-webkit-scrollbar {
            height: 5px;
        }
        /*
        .first-address-block-alc::-webkit-scrollbar-track {
            background: gainsboro;
            border-radius: 2px;
        }
        .first-address-block-alc::-webkit-scrollbar-thumb {
            background-color: darkgray;
            border-radius: 20px;
            border: 3px solid darkgray;
        }*/
        h3 {
            font: normal normal bold 14px/24px Open Sans;
            letter-spacing: 1px;
            color: #1d7e30;
            opacity: 0.8;
        }
    }
}
.MuiInput-underline::before {
    border-bottom: 2px solid #4786ff !important;
}
.listing-filter-block {
    margin-bottom: 30px;
}

@media screen and (min-width: 600px) {
    .listing-flex-container {
        justify-content: space-around;
    }
    #apartment-listing-card {
    }
    .listing-filter-block {
        display: flex;
        width: 100%;
        max-width: 1000px;
        justify-content: space-between;
        margin-bottom: unset;
    }
}

@media screen and (min-width: 1200px) {
    .listing-flex-container {
        display: grid;
        justify-content: space-around;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: min-content;
        max-width: 1000px;
        margin: auto;
    }
    #apartment-listing-card {
        width: 100%;
        max-width: 280px;
        .card-content-alc {
            h2 {
                margin-top: 0;
                font: normal normal bold 20px/26px Open Sans;
            }
        }
    }
}
