.MuiTableCell-alignRight {
    text-align: center !important;
    flex-direction: row-reverse;
}

// .MuiButton-root:hover {
//   text-decoration: none;
//   background-color: transparent;
//   color: #043b5c;
// }

.MuiButton-root {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Open Sans;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    // text-transform: none;
}

.MuiButton-root:hover {
    background-color: auto !important ;
    opacity: 0.5;
}

.MuiGrid-grid-sm-6 {
    flex-grow: 0;
    max-width: 61%;
    flex-basis: 50%;
}

.MuiTablePagination-root .MuiToolbar-root {
    display: flex;
    position: relative;
    align-items: center;
    flex-flow: row wrap;
}
