.receipt-page-container {
    background-color: #f1f1f1;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 20px;
    margin: auto;
    .rpc-back-btn {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .rpc-back-btn-span {
            display: none;
        }
    }
    .receipt-page-content {
        width: 100%;
        .pgc-top-ribbon {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            font-family: "Nunito";
            margin-bottom: 40px;
            img {
                cursor: pointer;
            }
            h3 {
                margin: 20px 0;
                font-weight: bold;
                font-size: 20px;
            }
            span {
                font-weight: lighter;
            }
        }
        .address-section {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            div {
                width: 50%;
            }
            .receipt-no {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                b {
                    color: darkblue;
                }
            }
            .company-address {
                margin-bottom: 20px;
            }
            p {
                font-size: 14px;
                margin-bottom: 0;
                color: black;
            }
        }
        .pgc-bottom-divs {
            margin-top: 40px;
            .pgc-pay-method {
                height: auto;
                width: 100%;
                background-color: #ffffff;
                margin-bottom: 40px;
                overflow-x: auto;
            }
            table {
                font-family: arial, sans-serif;
                border-collapse: collapse;
                width: 100%;
            }
            td,
            th {
                border: 1px solid #dddddd;
                text-align: left;
                padding: 8px;
            }
            tr:nth-child(even) {
                background-color: #dddddd;
            }
        }
        .subtotal-flex-container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .subtotal-divs {
                display: flex;
                align-items: center;
                label {
                    margin-bottom: 0;
                    margin-right: 10px;
                }
                div {
                    height: 40px;
                    width: 150px;
                    border-bottom: 1px solid black;
                    text-align: right;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
            }
            .subtotal-divs:last-child {
                label {
                    font-weight: bold;
                }
                div {
                    display: flex;
                    justify-content: space-between;
                    background-color: #dde9d3;
                }
            }
        }
    }
}

@media screen and (min-width: 700px) {
    .receipt-page-container {
        padding: 50px;
        .rpc-back-btn {
            .rpc-back-btn-span {
                display: unset;
            }
        }
        .receipt-page-content {
            width: 100%;
            max-width: 700px;
            margin: 0 auto;
            .pgc-bottom-divs {
                //width: 60%;
                margin: 30px auto 30px;
            }
        }
    }
}

@media screen and (min-width: 900px) {
}

@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 700px) {
}
