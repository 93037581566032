body {
  font-family: "Open Sans", sans-serif;
  background: #fafafa;
}

.m-auto {
  margin: auto;
}

.text-red {
  color: #c8202d;
}

.content-center {
  text-allign: center;
}

.div-box-shadow {
  background: #ffffff;
  box-shadow: 0px 4.2992px 31.1692px -8.59841px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 10px 40px;
}

.hr-rent-loan {
  border-bottom: 1px solid #888;
}

img {
  display: inline;
}

.bg-white {
  background-color: #fff;
}

.text-blue-900 {
  color: #10609f;
}

.text-green-900 {
  font-color: #0ec00a;
}

.bg-blue-900 {
  background-color: #10609f;
}

.dashboard-card {
  background: #ffffff;
  box-shadow: 0px 4px 28px -14px rgba(0, 0, 0, 0.25);
  border-radius: 10.2827px;
}

.text-yellow {
  color: #daa723;
}

.text-green {
  color: #2bbb28;
}
.text-green {
  color: #0ec00a;
}
.table-bg-gradient {
  background: linear-gradient(
    70.17deg,
    #052137 7.39%,
    rgba(13, 46, 72, 0.88) 56.82%,
    rgba(5, 33, 55, 0.78) 99.37%,
    rgba(7, 36, 59, 0.827247) 99.66%
  );
}

.text-white {
  color: rgba(255, 255, 255, 0.9) !important;
}

.MuiButton-root.Mui-disabled {
  color: #fff !important;
  background-color: #999;
}

.bg-blue-9000 {
  background-color: #10609f !important;
}

.css-qivjh0-MuiStepLabel-label {
  font-size: 0.5rem;
}
