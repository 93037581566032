@import "../../Styles/breakpoints.scss";
.app-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 11111111; // z-index
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    backdrop-filter: blur(3px);
    transition: all 0.375s;
    &.motion {
      visibility: hidden;
      opacity: 0;
    }
  }

  &.open {
    .app-modal-overlay {
      visibility: visible;
      opacity: 1;
    }
    .app-modal-body {
      transform: translateY(0);
      visibility: visible;
      opacity: 1;
    }
  }

  &.close {
    .app-modal-overlay {
      visibility: hidden;
      opacity: 0;
      transition: all 0.375s;
    }
    .app-modal-body {
      transform: translateY(3rem);
      opacity: 0;
      transition: all 0.375s;
    }
  }

  &.fullscreen {
    min-height: unset !important;
    .app-modal-body {
      height: 100%;
      width: 100% !important;
      display: flex;
      flex-direction: column;
    }

    .app-modal-body-content {
      width: 100% !important;
      height: 100% !important;
      max-height: unset !important;
      // max-height: 70% !important;
    }

    .app-modal-form-submit {
      // border-top: 1px solid #e0e0e0;
      margin-top: auto;
      padding: 20px 25px;

      @include mobile-up {
        padding: 20px 32px;
      }
    }
  }

  &.mobilescreen {
    .app-modal-body {
      position: fixed;
      width: 100% !important;
      bottom: -10px;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;

      &-header {
        background: unset;
      }
    }

    .app-modal-body-content {
      width: 100% !important;
      height: 100% !important;
      max-height: 60% !important;
    }
  }
}

.app-modal-body {
  background-color: #fff;
  width: 450px;
  z-index: 3;
  transition: all 0.375s;

  @include tablet-up {
    border-radius: 12px;
    // border-bottom-left-radius: 12px;
    // border-bottom-right-radius: 12px;
  }

  &.motion {
    transform: translateY(3rem);
    visibility: hidden;
    opacity: 0;
  }
}

.app-modal-body-header {
  display: flex;
  align-items: center;
  padding: 15px 25px;
  background: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  gap: 17px;

  @include mobile-up {
    padding: 15px 32px;
  }

  .close-icon {
    display: inline-block;
    border-radius: 50px;
    border-color: #42a99b;
    background: #ffff;
    padding: 10px;
    border-width: 1px;
    cursor: pointer;
    transition: all 0.35s;

    &:not(:disabled) {
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .header-title {
    font-weight: 600;
    font-size: 1rem;
    line-height: 20px;
    color: #000000;
    font-family: "Inter-Bold";
  }
}

.app-modal-body-content {
  padding: 20px 25px;
  max-height: 380px;
  overflow-y: auto;
  width: 100%;

  @include mobile-up {
    padding: 15px 32px;
  }
}
.app-modal-form-submit {
  padding: 10px 25px 40px;
  width: 100%;
  display: flex;
  justify-content: center;

  @include mobile-up {
    padding: 10px 32px 40px;
  }

  .form-buttons {
    display: flex;
    justify-content: center;
    // justify-content: space-between;
    gap: 15px;
    width: 100%;
  }
}
