#lipman-listing-page {
    padding: 0 0px 25px;
    .plp-lipman-filter-block {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .lpmp-properties-container {
        width: 100%;
        background-color: #e8f0f9;
        border: 1px solid gainsboro;
        .lpmp-properties-list-header {
            padding: 20px 5px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            border-bottom: 1px solid gainsboro;
            b {
                flex: 1;
            }
            b:first-child {
                margin-right: 30px;
            }
            b:last-child {
                flex: unset;
            }
        }
        .lpmp-properties-list {
        }
    }
}
.lpmp-lipman-property {
    width: 100%;
    height: 80px;
    padding: 0 5px 0 0;
    background-color: inherit;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid gainsboro;
    margin-top: 20px;
    .lpmp-lp-dropdown {
        margin: 0 8px;
    }
    .lpmp-lp-checkbox {
        padding: 0;
        margin: 0 5px 0 -8px;
    }
    .lpmp-lp-flex-div {
        flex: 1;
        overflow: auto;
        display: flex;
        flex-direction: column;
        margin-right: 5px;
        p {
            color: #043b5c;
            font-family: Open Sans-bold, sans-serif;
            font-size: 16px;
            line-height: 18px;
            font-weight: bolder;
            cursor: pointer;
            white-space: nowrap;
            margin-bottom: 5px;
        }
        span {
        }
    }
    span {
        flex: unset;
        color: #032f4a;
        font-family: Open Sans-light, sans-serif;
        font-weight: 600;
        font-size: 14px;
    }
}
.lpmp-lipman-apartment {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    padding: 20px 0 20px 40px;
    display: flex;
    align-items: flex-start;
    .lpmp-lp-checkbox {
        padding: 0;
        margin-right: 10px;
    }
    p {
        font-family: Nunito;
        color: #043b5c;
        font-size: 14px;
        font-weight: 400;
    }
}
.plan-initiate-btn {
    position: fixed;
    left: calc(50vw - 50px);
    bottom: 0;
    width: 200;
    color: #258f7a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
    border-radius: 5;
    padding: 0 10px;
    animation-name: example;
    animation-duration: 1s;
}
@keyframes example {
    from {
        bottom: -40px;
    }
    to {
        bottom: 0;
    }
}

@media screen and (min-width: 700px) {
    #lipman-listing-page {
        .lpmp-properties-container {
            .lpmp-properties-list-header {
                b {
                    flex: 10;
                }
                b:last-child {
                    flex: 3;
                }
            }
            .lpmp-properties-list {
            }
        }
    }
    .lpmp-lipman-property {
        padding: 0 25px;
        .lpmp-lp-flex-div {
            flex: 8;
        }
        span {
            flex: 2;
        }
    }
    .lpmp-lipman-apartment {
        padding-left: 100px;
    }
    .lpmp-lipman-apartment2 {
        padding-left: 60px;
    }
}

@media screen and (min-width: 1200px) {
    .lpmp-lipman-apartment {
        padding-left: 150px;
    }
    .lpmp-lipman-apartment2 {
        padding-left: 70px;
    }
}
