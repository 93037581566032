
legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .1rem; 
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}