.username{
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 40px;
}

.location-image{
    width: 13px;
    height: 17px;
}

.btn-schedule{
    margin-left: 1rem;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 600px) {
    .btn-interest{
        margin-bottom: 1rem;
    }
    .btn-schedule{
        margin-left: 0;
    }
    .btn-delete{


    }
}