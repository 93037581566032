.payment-page-container {
    background-color: #f1f1f1;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 20px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: "Montserrat", "Courier New", Courier, monospace;
    }
    p,
    div,
    span,
    img {
        font-family: "Nunito", "Courier New", Courier, monospace;
    }
    .payment-page-content {
        margin: 0 5px;
        .pgc-top-ribbon {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            font-family: "Nunito";
            a {
                flex: 2;
            }
            h3 {
                margin: 20px 0;
                font-weight: bold;
                font-size: 20px;
                flex: 6;
                text-align: center;
            }
            span {
                font-weight: lighter;
                flex: 2;
                text-align: right;
            }
        }
        .pgc-bottom-divs {
            margin-top: 80px;
            .pgc-balance-ribbon {
                height: auto;
                background-color: #ffffff;
                padding: 10px 10px 10px 20px;
                margin-bottom: 30px;
                h4 {
                    opacity: 1;
                    color: #000000;
                    //font-family: "Nunito";
                    font-size: 18px;
                    margin-bottom: 10px;
                }
                div {
                    font-size: 20px;
                    font-weight: bolder;
                    font-family: "Nunito";
                    span {
                        color: #000000;
                    }
                    input {
                        margin-left: 10px;
                        margin-bottom: 10px;
                        max-width: 200px;
                        text-align: center;
                        font-weight: bold;
                    }
                }
                select {
                    font-size: 14px;
                    border: none;
                    border-bottom: 1px solid;
                }
            }
            .pgc-pay-method {
                height: auto;
                width: 100%;
                margin-bottom: 30px;
                .pgc-pm-selected {
                    background-color: #ffffff;
                    height: 120px;
                    padding: 20px;
                    h3 {
                        margin-top: 0;
                        font-size: 18px;
                        line-height: 20px;
                        margin-bottom: 20px;
                    }
                    p {
                        b,
                        span {
                            margin-left: 10px;
                        }
                    }
                }
            }
            .pgc-pay-method-select {
                margin-bottom: 20px;
                height: 90px;
                .pgc-pm-selected {
                    height: 90px;
                }
            }
            .pgc-spacer-header {
                margin: 0 auto 20px;
                font-size: 20px;
                color: darkgray;
            }
            .pgc-paragraph {
                font-size: 16px;
                color: #000000;
                opacity: 1;
                font-weight: normal;
            }
        }
    }
    .accordion-view-window {
        width: 100%;
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 700px) {
    .payment-page-container {
        padding: 50px;
        .payment-page-content {
            margin: 0 50px;
            .pgc-bottom-divs {
                width: 600px;
                margin: 80px auto 0;
                .pgc-balance-ribbon {
                    height: 60px;
                    margin: 80px auto 30px;
                    display: flex;
                    align-items: center;
                    height: 60px;
                    h4 {
                        margin-right: 20px;
                        margin-bottom: 0;
                    }
                    div {
                        input {
                            margin-right: 20px;
                            margin-bottom: 0;
                        }
                    }
                    select {
                    }
                }
            }
        }
        .accordion-view-window {
            width: 600px;
            margin-bottom: 30px;
        }
    }
}

@media screen and (min-width: 900px) {
}

@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 700px) {
}
