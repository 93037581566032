.lbc-23de23d {
    .popm-contact-info-col {
        margin-bottom: 40px;
        .popm-cic-flex {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 10px;
        }
        .popm-cic-sbmt-div {
        }
    }
    h2 {
        font-size: 14px;
        margin-bottom: 20px;
        font-weight: bold;
    }
    h4 {
        font-size: 14px;
        margin-bottom: 20px;
        font-weight: bold;
    }
    .lbc-bank-accs {
        margin-bottom: 30px;
        .lbc-bank-accs-item {
            display: flex;
            justify-content: space-between;
            width: 100%;
            max-width: 400px;
            padding: 5px 10px;
            border-radius: 4px;
            border: 2px solid darkgray;
            margin-bottom: 20px;
            .lbc-bank-name {
                color: black;
            }
            .lbc-an-name-div {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                p:first-child {
                    color: blue;
                    font-weight: bold;
                    margin-right: 10px;
                }
                .lbc-acc-name {
                    font-size: 14px;
                }
            }
        }
    }
    .lbc-add-new-acc-form {
    }
}

@media screen and (min-width: 767px) {
    .lbc-23de23d {
        .lbc-bank-accs {
            .lbc-bank-accs-item {
                max-width: 800px;
            }
        }
    }
}
