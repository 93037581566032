#single-apartment-ribbon {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 5px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid gainsboro;
    font-size: 14px;
    cursor: pointer;
    .sar-tile-flex-div {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-right: 50px;
        h5 {
            color: #062239;
            font-size: 14px;
            font-weight: 300;
            margin: 0;
        }
        p {
            font-size: 14px;
        }
    }
}

@media screen and (min-width: 700px) {
    #single-apartment-ribbon {
        padding: 0 10px;
    }
}
