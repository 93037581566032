.progress-bar-show-of-interest {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    div {
        display: flex;
        align-items: center;
        margin-right: 5px;
        .check-mark {
            min-width: 20px;
            height: 20px;
            border-radius: 13px;
            background-color: #c8202d;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 3px;
            svg {
                font-size: 10px;
            }
        }
        .check-mark-outlined {
            background-color: transparent;
            border: 1.5px solid #052137;
        }
        span {
            margin-right: 5px;
            font-size: 12px;
        }
    }
}

@media screen and (min-width: 500px) {
    .progress-bar-show-of-interest {
        div {
            margin-right: 20px;
            .check-mark {
                width: 38px;
                height: 38px;
                border-radius: 25px;
                margin-right: 20px;
                svg {
                    font-size: 17px;
                }
            }
            .check-mark-outlined {
            }
            span {
                margin-right: 10px;
                font-size: 14px;
            }
        }
    }
}
