a,
a:hover,
a:focus {
  color: #4e6273;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

.text-color {
  color: #043b5c;
}

.small-p {
  font-size: 14px;
  color: #043b5c;
}

/* ---------------------------------------------------
  SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  // align-items: stretch;
  // height: 100vh;
}

.logo-sm {
  display: none;
}

.collapsed {
  .sidebar {
    min-width: 70px;
    max-width: 70px;
    &.active {
      margin-left: 0px;
    }
    .sidebar-header {
      padding-top: 60px;
    }
  }

  .sidebar-header {
    .logo-lg {
      display: none;
    }

    .logo-sm {
      display: block;
    }
  }

  .sidebar ul {
    padding-top: 60px !important;
    > li {
      position: relative;
      white-space: nowrap;

      > a {
        > img {
          display: block;

          &.sidebar-icon {
            width: 2rem;
            // padding: 0rem 0.3rem;
            margin: 0 auto;
          }
        }

        > span {
          display: none;
        }
      }
    }
  }

  .signout-button {
    > img {
      display: block;
      &.sidebar-icon {
        margin-left: 10px;
      }
    }

    > span {
      display: none;
    }
  }

  .user-info {
    > p {
      display: none;
    }
  }

  .img-contain {
    & img {
      border-radius: 100%;
      width: 70%;
      // height: 100;
    }
  }
}

.sidebar {
  min-width: 240px;
  max-width: 240px;
  background: #062239;
  color: #fff;
  transition: all 0.3s;
  height: 100% !important;
  // max-height: 100% !important;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  // left: 0;
  // max-height: 900px;
}

// .sidebar.active {
//   // margin-left: -250px;
//   left: -80px;
//   // margin-left: -170px;
// }

.hidelinks {
  display: none;
}

.sidebar .sidebar-header {
  padding: 20px;
  background: #062239;
}

// .sidebar ul.components {
//   padding-top: 10px;
//   padding-bottom: 5px;
//   border-bottom: 1px solid #062239;
//   margin-bottom: 0px !important;
// }

.sidebar ul {
  padding-top: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #062239;
  margin-bottom: 0px !important;
  list-style: none;
  // padding-left: 20px;
  // padding-right: 20px;
}

@media only screen and (max-width: 768px) {
  .sidebar ul {
    padding-top: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid #062239;
    margin-bottom: 0px !important;
    list-style: none;
  }
}

ul.submenu {
  padding-top: 0px;
}

// ul.submenu li a {
//   color: #f4f5f7;
// }

ul.submenu li a:hover {
  color: #fff;
  background: #043b5c;
  border-left: none !important;
  transition: 0.2s ease-out;
}

.sidebar ul p {
  color: #576a7a;
  padding: 10px;
  margin-top: 20px;
}

.user-info p {
  // color: #576a7a;
  // padding: 10px;
  // margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.sidebar ul li a {
  padding: 10px;
  font-size: 14px;
  display: block;
}

.sidebar ul li a:hover {
  color: #fff;
  background: #043b5c;
  // border-left: 7px solid #fafafa;
  transition: 0.2s ease-out;
}

.sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #043b5c;
}

.activeClass {
  color: #c8202d !important;
  // background: #043b5c;
  // border-left: 7px solid #fafafa;
  transition: 0.2s ease-out;
  // border-radius: 10px
}
.activeClass-submenu {
  color: #fff !important;
  background: #043b5c;
  transition: 0.2s ease-out;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #043b5c;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

.center {
  text-align: left;
  margin-top: 5px;
}

.sidebar-icon {
  width: 1.7rem;
  padding-right: 0.5rem;
}
.sidebar-icon-log {
  width: 1.2rem;
  padding-right: 0.5rem;
}

.user-id {
  margin-top: -30px;
  font-size: 14px;
  padding: 0px !important;
  text-align: center;
}

.signout-button {
  // margin-left: 2%;
  border: 0;
  background: transparent;
  color: #4e6273;
  // font-size: 14px;
  // outline: none;
  padding: 10px;

  &:hover {
    > img {
      opacity: 1;
    }
  }

  > img {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

/* ---------------------------------------------------
  CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  padding: 30px 70px;
  min-height: 100vh;
  // transition: all 0.3s;
  background: #f4f5f7;
}

.hr {
  border-color: #285873;
  margin-top: -17px;
  margin-bottom: 55px;
}

@media only screen and (max-width: 800px) {
  #content {
    padding: 30px 40px;
  }
}
@media only screen and (max-width: 500px) {
  #content {
    padding: 30px 20px;
  }
}

.card {
  border: 1px solid rgba(255, 255, 255, 0.719);
  border-radius: 10px;
}

.img-contain {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  & img {
    border-radius: 100%;
    width: 40%;

    // height: 100;
  }
}

/* ---------------------------------------------------
  MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 990px) {
  #content {
    width: 100%;
    padding: 30px 40px;
    // min-height: 100vh;
    // transition: all 0.3s;
    background: #f4f5f7;
    //overflow-y: scroll;
  }

  .wrapper {
    display: flex;
    width: 100%;
    // align-items: stretch;
    height: auto;
  }
}

@media (max-width: 969px) {
  // .sidebar {
  //   margin-left: -250px;
  // }

  // #side {
  //   position: fixed;
  //   z-index: 10000;
  // }

  #side_mobile {
    position: fixed;
    z-index: 10000;
  }
  .sidebar.active {
    // margin-left: 0;
    // position: absolute;
    z-index: 20;
    max-width: 200px;
    min-width: 200px;
    // min-height: 1650px;
    left: -0px;
    transition: left 0.9s;
    position: fixed;
    // z-index: 10000;
  }
  #sidebarCollapse span {
    display: none;
  }
}

@media (max-width: 969px) {
  // .sidebar {
  //   max-width: 200px;
  //   min-width: 200px;
  // }

  .sidebar {
    // margin-left: -250px;
    left: -250px;
    position: fixed;
    z-index: 1000;
    transition: left 0.9s;
    // min-height: 1650px;
    // transition: left 0.3s;
    overflow: auto;
  }
}
// @media (max-width: 200.98px) {
//   .sidebar {
//     margin-left: -250px;
//   }
// }
