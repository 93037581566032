.mainimage{
    max-width: 100%;
    margin-left: -15px;
    border-radius: 10px;
}

@media only screen and (max-width: 600px) {
    .mainimage{
        margin-left: -1px;
    }
}