button:focus {
    outline: none;
}

.MuiFormControl-fullWidth .MuiOutlinedInput-adornedStart {
    padding-left: 0px;
}

.strike {
    display: block;
    // text-align: center;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 7%;
}

.strike > span {
    position: relative;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: #043b5c;
}

// .strike > span:before,
.strike > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #043b5c54;
}

// .strike > span:before {
//   right: 100%;
//   margin-right: 15px;
// }

.strike > span:after {
    left: 100%;
    margin-left: 15px;
}

.hide {
    display: none;
}

.mainpaperdisplay:hover .hide {
    display: block;
}

.MuiOutlinedInput-root .MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 32px;
    padding: 4%;
}
