.personal-details-show-of-interest {
    width: 100%;
    max-width: 800px;
    margin: auto;
    .avatar-container {
        margin-bottom: 50px;
        .avatar-error {
            text-align: center;
            font-size: 12px;
            color: red;
            margin: 20px auto 0;
        }
        .avatar {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            background-color: gray;
            position: relative;
            margin: auto;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
            input {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 50px;
                z-index: 2;
                visibility: hidden;
            }
            label {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background: #ffffff;
                box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15);
                cursor: pointer;
                position: absolute;
                bottom: 0;
                right: 0;
                margin-bottom: 0;
            }
        }
    }
    .form-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .textfield-soi {
            width: 100%;
            margin-bottom: 20px;
        }
        .MuiOutlinedInput-root {
            background: linear-gradient(97.94deg, rgba(0, 0, 0, 0.04) 2.89%, rgba(227, 225, 225, 0.08) 95.96%);
        }
    }
    .btn-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        //max-width: 300px;
        margin: 30px auto;
        .btn-proceed {
            width: 45%;
            height: 45px;
            border-radius: 6px;
        }
    }
}

@media screen and (min-width: 500px) {
    .personal-details-show-of-interest {
        width: 100%;
        max-width: 800px;
        margin: auto;
        .form-container {
            .textfield-soi {
                width: 45%;
                margin-bottom: 40px;
            }
        }
        .form-container-b {
            padding-top: 50px;
        }
        .btn-container {
            max-width: unset;
            width: 400px;
            .btn-proceed {
                width: 150px;
            }
        }
    }
}
