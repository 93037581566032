.small-image{
    max-width: 100%;
    border-radius: 10px;
}

/* responsiveness */
/* for mobile */
@media only screen and (max-width: 600px) {
    .small-image{
        margin-bottom: 15px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .small-image{
        margin-bottom: 15px;
    }
}