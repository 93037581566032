.inner-listing-page{
    .available-days {
        .no-schedule-li{
            display: block;
            cursor: unset;
        }
        .no-schedule-li:hover{
            background-color: #F4F5F7;
            h4{
                color: #212529;
            }
        }
    }
}
