@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
  overscroll-behavior: contain none !important;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

input {
  border: none;
  outline: none;
}

h4,
p {
  margin: 0;
}

@font-face {
  font-family: "Open Sans";
  src: url(./assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: "Open Sans-bold";
  src: url(./assets/font/OpenSans-Bold.ttf);
}

@font-face {
  font-family: "Open Sans-light";
  src: url(./assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: "Open Sans-Semibold";
  src: url(./assets/font/OpenSans-Semibold.ttf);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 1px;
  border-radius: 10px;
}

::-webkit-scrollbar {
  height: 10px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 10px;
  /* width of vertical scrollbar */
  border: 1px solid red;
  border-radius: 10px;
}

.payment-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 5px #10609f;
  border-radius: 7px;
  padding: 1.5rem;
  width: max-content;
}

.payment-filter div {
  display: flex;
  align-items: center;
}

.payment-filter .line {
  width: 1px;
  height: 4.5rem;
  background-color: #99999959;
  margin: 10px 0;
}

.centralize {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-sb {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.gap-3 {
  gap: 3rem;
}

.gap-4 {
  gap: 4rem;
}

.gap-5 {
  gap: 5rem;
}

.gap-6 {
  gap: 6rem;
}

.gap-7 {
  gap: 7rem;
}

.mtop-1 {
  margin-top: 1rem !important;
}

.mtop-2 {
  margin-top: 2rem !important;
}

.mtop-3 {
  margin-top: 3rem !important;
}

.mtop-4 {
  margin-top: 4rem !important;
}

.mtop-5 {
  margin-top: 5rem !important;
}

.mtop-6 {
  margin-top: 6rem !important;
}

.mtop-7 {
  margin-top: 7rem !important;
}

.mtop-8 {
  margin-top: 8rem !important;
}

.my-shadow {
  box-shadow: 1px 1px 8px #00000020;
}

.show-calculator.tenant {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 3rem 4rem;
  box-shadow: 1px 1px 1rem #00000020;
  border-radius: 1rem;
}

.show-calculator {
  margin: 50px 0px;
}

.radio-input {
  display: block;
  position: relative;
  font-size: 1rem;
  font-weight: 500;
  padding-left: 1.5rem;
  color: #052137;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 500px) {
  .radio-input {
    font-size: 15px;
  }
}

.radio-input input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: solid 2px #10609f;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark.red {
  border: solid 2px #c8202d;
}

.radio-input:hover input ~ .checkmark {
  background-color: #cccccc3d;
}

.radio-input input:checked ~ .checkmark {
  background-color: #10609f;
  border: none;
}

.radio-input input:checked ~ .checkmark.red {
  background-color: #fff;
  border: solid 2px #c8202d;
}

.checkmark::after {
  content: "\2714";
  font-size: 1rem;
  color: white;
  display: none;
}

.checkmark.red::after {
  content: "\2714";
  font-size: 1.5rem;
  color: #c8202d;
  display: none;
}

.radio-input input:checked ~ .checkmark::after,
.radio-input input:checked ~ .checkmark.red::after {
  display: block;
}

@media (max-width: 420px) {
  .show-calculator.tenant {
    padding: 2rem;
  }
}

.blue-banner {
  width: 60% !important;
  margin: 2rem auto;
}

@media (max-width: 800px) {
  .blue-banner {
    width: 100% !important;
  }
}

@media (max-width: 800px) {
  .payment-history {
    padding: 0rem;
  }
}

.text-link {
  font-size: 1rem;
}

@media (max-width: 500px) {
  .text-link {
    font-size: 15px;
    padding: 1rem;
  }
}

.custom-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  gap: 2.5rem;
}

@media (max-width: 500px) {
  .custom-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 2rem;
  }
}

@media (max-width: 400px) {
  .custom-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    gap: 1rem;
  }
}

.content-center {
  width: 80%;
  margin: 0 auto;
  padding: 3rem 0;
}

.calc-details h2 {
  box-shadow: 1px 1px 1rem #00000020;
}

@media (max-width: 800px) {
  .content-center {
    width: 100%;
    padding: 3rem 5rem;
  }
}

.btn-two {
  display: none;
}

@media (max-width: 600px) {
  .btn-one {
    display: none;
  }

  .btn-two {
    display: flex;
  }

  .pad {
    padding: 1.2rem 0;
  }
}

@media (max-width: 500px) {
  .content-center {
    width: 100%;
    padding: 3rem 2rem;
  }

  .calc-details {
    box-shadow: 1px 1px 1rem #00000020;
    padding: 15px;
    border-radius: 1rem;
    margin-bottom: 10px;
    align-items: flex-start;
  }

  .calc-details h2 {
    box-shadow: none;
    padding: 0 !important;
    text-align: left;
  }

  .calc-total {
    text-align: left;
  }

  .calc-total h1:first-child {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .flexCol {
    flex-direction: column;
  }

  .wFull {
    width: 100%;
  }
}

.billbox {
  transition: all 0.7s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.billbox:hover {
  transform: scale(1.03);
}

.bill-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  gap: 2rem;
  margin: 2rem 0rem !important;
}

@media (max-width: 500px) {
  .bill-grid {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .bill-actions {
    flex-direction: column;
    gap: 1.5rem;
  }

  .bill-actions button {
    width: 100%;
  }
}

.billbox {
  width: 100%;
  max-width: 550px;
  padding: 1.5rem !important;
  box-shadow: 1px 1px 1rem #00000020;
  border-radius: 1rem;
  cursor: pointer;
}

.billModal {
  width: 50%;
}

.billModal .term {
  row-gap: 1.3rem;
  column-gap: 3rem;
}

.table-data {
  text-align: left;
  padding: 15px;
  border-bottom: solid 1px #05213750;
  border-top: solid 1px #052137;
  /* max-width: 500px; */
}

.table-action {
  background-color: #094c80;
  color: white;
  padding: 6px 13px;
  margin: 0 15px 0 3px;
  border-radius: 5px;
  cursor: pointer;
  min-width: max-content;
}
.table-action.red {
  background-color: #c8202d;
}
.table-action.red.icon {
  padding: 0px 10px;
  border-radius: 100%;
}

.MuiOutlinedInput-root .MuiSelect-outlined.MuiSelect-outlined {
  padding: 17px 14px !important;
}

.assign-modal {
  background-color: white;
  width: 50%;
  height: auto;
  padding: 50px 17px;
  overflow: auto;
  border-radius: 7px;
  position: relative;
  z-index: 1000;
  max-height: 53rem;
}

@media (max-width: 800px) {
  .assign-modal {
    width: 95%;
  }
}

@media (min-width: 1440px) {
  .billModal {
    width: 50%;
  }
}

@media (max-width: 1440px) {
  .billModal {
    width: 60%;
  }
}

@media (max-width: 1024px) {
  .billModal {
    width: 80%;
  }
}

@media (max-width: 800px) {
  .billModal {
    width: 90%;
    padding: 2rem;
  }
}

@media (max-width: 500px) {
  .billModal {
    width: 90%;
    padding: 2rem;
  }
}

@media (max-width: 450px) {
  .default-term {
    position: absolute;
    bottom: -32%;
    right: 3%;
  }
}

@media (max-width: 500px) {
  .billModal .inputs {
    flex-direction: column;
    width: 100%;
  }

  .input-full {
    width: 100%;
  }

  .mtop-0 {
    margin: 0 !important;
  }
}
@media (max-width: 500px) {
  .btn {
    width: 100%;
  }
}
