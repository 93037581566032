.skeleton {
    opacity: 0.7;
    animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
    width: 100%;
    height: 0.8rem;
    margin-bottom: 0.25rem;
    border-radius: 0.125rem;
}

.skeleton-text:last-child {
    margin-bottom: 0;
    width: 80%;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}
