.show-interest-success-modal {
    width: 90vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .check-mark-div {
        width: 100px;
        height: 100px;
        margin: auto;
        border-radius: 50%;
        background-color: #0ec00a;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        svg {
            font-size: 70px;
        }
    }
    h3 {
        margin: 0 0 20px;
    }
    p {
        margin: 0 0 40px;
    }
    .btn-div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .btn-proceed {
            width: 185px;
            margin-bottom: 20px;
        }
    }
}

@media screen and (min-width: 400px) {
    .show-interest-success-modal {
        width: 600px;
        padding: 40px 0 20px;
        .btn-div {
            width: 400px;
        }
    }
}
