// .input {
//   display: block;
//   width: 100%;
//   padding: 7px 16px;
//   line-height: 25px;
//   font-size: 14px;
//   font-weight: 600;
//   font-family: inherit;
//   border-radius: 5px;
//   -webkit-appearance: none;
//   border: 1px solid #06223968;
//   transition: border 0.3s ease;
//   &::placeholder {
//     color: var(--input-placeholder);
//   }
//   &:focus {
//     outline: none;
//   }
// }

.form-grop {
  position: relative;
  display: flex;
  width: 100%;
  & > span {
    white-space: nowrap;
    display: block;
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
    &:first-child {
      border-radius: 6px 0 0 6px;
    }
    &:last-child {
      border-radius: 0 6px 6px 0;
    }
    &:not(:first-child) {
      margin-left: -1px;
    }
  }

  & input {
    white-space: nowrap;
    display: block;
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
    &:first-child {
      border-radius: 6px 0 0 6px;
    }
    &:last-child {
      border-radius: 0 6px 6px 0;
    }
    &:not(:first-child) {
      margin-left: -1px;
      border-left: 0;
    }
  }
  input {
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    width: 1%;
    margin-top: 0;
    margin-bottom: 0;
  }
  & > span {
    text-align: center;
    padding: 11px 18px;
    font-size: 14px;
    // line-height: 25px;
    background: #eef4fb;
    border: 1px solid #06223968;
    transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;

    & > img {
      width: 20px;
    }
  }
  &:focus-within {
    & > span {
      color: var(--group-color-focus);
      background: var(--group-background-focus);
      border-color: #06223968;
    }
  }
}
